/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
if (process.env.NODE_ENV === 'development') {
    require('./src/styles/main.scss');
}

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
    const isChangePage = prevLocation && prevLocation.pathname !== location.pathname;
    document.documentElement.classList.toggle('scroll-smooth', !isChangePage);

    setTimeout(() => document.documentElement.classList.add('scroll-smooth'), 50);
}
