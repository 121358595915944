// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-us-jsx": () => import("./../../../src/templates/about-us.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-app-jsx": () => import("./../../../src/templates/app.jsx" /* webpackChunkName: "component---src-templates-app-jsx" */),
  "component---src-templates-apps-jsx": () => import("./../../../src/templates/apps.jsx" /* webpackChunkName: "component---src-templates-apps-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-blog-posts-jsx": () => import("./../../../src/templates/blog-posts.jsx" /* webpackChunkName: "component---src-templates-blog-posts-jsx" */),
  "component---src-templates-builder-jsx": () => import("./../../../src/templates/builder.jsx" /* webpackChunkName: "component---src-templates-builder-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-manual-jsx": () => import("./../../../src/templates/manual.jsx" /* webpackChunkName: "component---src-templates-manual-jsx" */),
  "component---src-templates-paid-services-jsx": () => import("./../../../src/templates/paid-services.jsx" /* webpackChunkName: "component---src-templates-paid-services-jsx" */),
  "component---src-templates-privacy-policy-jsx": () => import("./../../../src/templates/privacy-policy.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-jsx" */),
  "component---src-templates-product-feedback-jsx": () => import("./../../../src/templates/product-feedback.jsx" /* webpackChunkName: "component---src-templates-product-feedback-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */),
  "component---src-templates-release-notes-jsx": () => import("./../../../src/templates/release-notes.jsx" /* webpackChunkName: "component---src-templates-release-notes-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-shopify-themes-jsx": () => import("./../../../src/templates/shopify-themes.jsx" /* webpackChunkName: "component---src-templates-shopify-themes-jsx" */),
  "component---src-templates-theme-jsx": () => import("./../../../src/templates/theme.jsx" /* webpackChunkName: "component---src-templates-theme-jsx" */),
  "component---src-templates-theme-support-jsx": () => import("./../../../src/templates/theme-support.jsx" /* webpackChunkName: "component---src-templates-theme-support-jsx" */)
}

